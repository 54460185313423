import {createRouter, createWebHistory, NavigationGuardNext, RouteLocationNormalized, RouteRecordRaw} from 'vue-router'
import {defineAsyncComponent,computed} from 'vue'
import {getToken,getMarsToken} from '@/utils/auth'
import {useStore} from 'vuex'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'IndexLayout',
    redirect: {name: 'Index'},
    component: defineAsyncComponent(() => import(/* webpackChunkName: "home" */ '../layout/MainPageLayout.vue')),
    children: [
      {
        path: '/home',
        name: 'Index',
        component: defineAsyncComponent(() => import(/* webpackChunkName: "home" */ '../views/Index/Index.vue')),
      },
    ],
  },
  {
    path: '/discovery/index',
    name: 'DiscoveryLayout',
    redirect: {name: 'Discovery'},
    component: defineAsyncComponent(() => import(/* webpackChunkName: "home" */ '../layout/MainPageLayout.vue')),
    children: [
      {
        path: '/discovery',
        name: 'Discovery',
        component: defineAsyncComponent(() => import(/* webpackChunkName: "home" */ '../views/Discovery/Index.vue')),
      },
    ],
  },
  {
    path: '/profit/index',
    name: 'ProfitLayout',
    redirect: {name: 'Profit'},
    component: defineAsyncComponent(() => import(/* webpackChunkName: "home" */ '../layout/MainPageLayout.vue')),
    children: [
      {
        path: '/profit',
        name: 'Profit',
        component: defineAsyncComponent(() => import(/* webpackChunkName: "home" */ '../views/Profit/Index.vue')),
      },
    ],
  },
  {
    path: '/mine',
    name: 'Mine',
    component: defineAsyncComponent(() => import(/* webpackChunkName: "home" */ '../views/Mine/Index.vue')),
  },
  // 平台详情页
  {
    path: '/productDetail',
    name: 'ProductDetail',
    component: defineAsyncComponent(() => import(/* webpackChunkName: "test" */ '../views/ProductDetail/Index.vue')),
  },
  // 数据详情
  {
    path: '/dataDetail',
    name: 'DataDetail',
    component: defineAsyncComponent(() => import(/* webpackChunkName: "test" */ '../views/DataDetail/Index.vue')),
  },
  // 我的钱包
  {
    path: '/myWallet',
    name: 'MyWallet',
    component: defineAsyncComponent(() => import(/* webpackChunkName: "test" */ '../views/MyWallet/Index.vue')),
  },
  // 提现详情
  {
    path: '/cashDetail',
    name: 'CashDetail',
    component: defineAsyncComponent(() => import(/* webpackChunkName: "test" */ '../views/CashDetail/Index.vue')),
  },
  // 新闻详情页
  {
    path: '/contenDetail',
    name: 'ContenDetail',
    component: defineAsyncComponent(() => import(/* webpackChunkName: "test" */ '../views/ContenDetail/Index.vue')),
  },
  // 我的推广
  {
    path: '/promotion',
    name: 'Promotion',
    component: defineAsyncComponent(() => import(/* webpackChunkName: "test" */ '../views/Promotion/Index.vue')),
  },
  // 添加账户
  {
    path: '/accounts',
    name: 'Accounts',
    component: defineAsyncComponent(() => import(/* webpackChunkName: "test" */ '../views/Accounts/Index.vue')),
  },
  // 银行卡片列表
  {
    path: '/bankList',
    name: 'BankList',
    component: defineAsyncComponent(() => import(/* webpackChunkName: "test" */ '../views/BankList/Index.vue')),
  },
  // 收益列表
  {
    path: '/incomes',
    name: 'Incomes',
    component: defineAsyncComponent(() => import(/* webpackChunkName: "test" */ '../views/Incomes/Index.vue')),
  },
  // 添加银行账户
  {
    path: '/bankAccounts',
    name: 'BankAccounts',
    component: defineAsyncComponent(() => import(/* webpackChunkName: "test" */ '../views/BankAccounts/Index.vue')),
  },
  // 填写发布链接
  {
    path: '/publishLink',
    name: 'PublishLink',
    component: defineAsyncComponent(() => import(/* webpackChunkName: "test" */ '../views/PublishLink/Index.vue')),
  },
  // 申请关键词
  {
    path: '/keyword',
    name: 'Keyword',
    component: defineAsyncComponent(() => import(/* webpackChunkName: "test" */ '../views/Keyword/Index.vue')),
  },
  // 个人信息编辑 Personal
  {
    path: '/personal',
    name: 'Personal',
    component: defineAsyncComponent(() => import(/* webpackChunkName: "test" */ '../views/Personal/Index.vue')),
  },
  // 修改密码
  {
    path: '/editPassword',
    name: 'EditPassword',
    component: defineAsyncComponent(() => import(/* webpackChunkName: "test" */ '../views/EditPassword/Index.vue')),
  },
  // 内容详情页面
  {
    path: '/detail',
    name: 'Detail',
    component: defineAsyncComponent(() => import(/* webpackChunkName: "test" */ '../views/Detail/Index.vue')),
  },
  // 人拉人邀请页面
  {
    path: '/invites',
    name: 'Invites',
    component: defineAsyncComponent(() => import(/* webpackChunkName: "test" */ '../views/Invites/Index.vue')),
  },
  // 创建博主 CreateBlogger
  {
    path: '/createBlogger',
    name: 'CreateBlogger',
    component: defineAsyncComponent(() => import(/* webpackChunkName: "test" */ '../views/CreateBlogger/Index.vue')),
  },
  // 创建团长 CreateDirector
  {
    path: '/createDirector',
    name: 'CreateDirector',
    component: defineAsyncComponent(() => import(/* webpackChunkName: "test" */ '../views/CreateDirector/Index.vue')),
  },
  // 消息通知
  {
    path: '/messages',
    name: 'MessageList',
    component: defineAsyncComponent(() => import(/* webpackChunkName: "test" */ '../views/MessageList/Index.vue')),
  },


  // 用户协议
  {
    path: '/userAgreement',
    name: 'UserAgreement',
    component: defineAsyncComponent(() => import(/* webpackChunkName: "test" */ '../views/UserAgreement/Index.vue')),
    meta: {
      isAuth: false,
    },
  },
  // 隐私政策
  {
    path: '/privacyPolicy',
    name: 'PrivacyPolicy',
    component: defineAsyncComponent(() => import(/* webpackChunkName: "test" */ '../views/PrivacyPolicy/Index.vue')),
    meta: {
      isAuth: false,
    },
  },



  // 注册/登录等
  {
    path: '/register',
    name: 'Register',
    component: defineAsyncComponent(() => import(/* webpackChunkName: "test" */ '../views/Register/Index.vue')),
    meta: {
      isAuth: false,
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: defineAsyncComponent(() => import(/* webpackChunkName: "test" */ '../views/Login/Index.vue')),
    meta: {
      isAuth: false,
    },
  },
  {
    path: '/resetPassword',
    name: 'ResetPassword',
    component: defineAsyncComponent(() => import(/* webpackChunkName: "test" */ '../views/ResetPassword/Index.vue')),
  },

  {
    path: '/test',
    name: 'Test',
    component: defineAsyncComponent(() => import(/* webpackChunkName: "test" */ '../views/Test.vue')),
    meta: {
      isAuth: false,
    },
  },
  {
    path: '/404',
    name: '404',
    component: defineAsyncComponent(() => import(/* webpackChunkName: "404" */ '../views/404.vue')),
    meta: {
      isAuth: false,
    },
  },
  {
    path: '/:catchAll(.*)', // 404 页面
    redirect: '/',
    meta: {
      isAuth: false,
    },
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
  
  if (getToken() || getMarsToken()) { 
    if (to.path === '/login') { //如果登录成功访问登录页跳转到主页
      next({path: '/'})
    } 
  } 
  next()
  
})

export default router
